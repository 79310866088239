import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { customSelectBockedYellow } from '../components/Layouts/react-select-custom';
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';
import add from '../assets/img/icons/ADD.svg';
import clearBlueUpload from '../assets/img/icons/clearBlueUpload.svg';
import warningAlert from '../assets/img/icons/alert-circle-orange.svg';
import dangerAlert from '../assets/img/icons/alert-circle.svg';
import AddBlue from '../assets/img/icons/AddBlue.svg';
import ordDownload from '../assets/img/icons/ordDownload.svg';
import ordBlueSearch from '../assets/img/icons/ordBlueSearch.svg';
import darkBlueDownload from '../assets/img/icons/darkBlueDownload.svg';
import ordGrayBack from '../assets/img/icons/ordGrayBack.svg';
import grayClone from '../assets/img/icons/grayClone.svg';
import penFullGray from '../assets/img/icons/penFullGray.svg';
import ordRightArrow from '../assets/img/icons/ordRightArrow.svg';
import yellowRightArrow from '../assets/img/icons/yellowRightArrow.svg';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { OrdBiTable } from '../OrderingModule/OrderingComponents/OrdBiTable';
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import { OrdDropZone } from '../OrderingModule/OrderingComponents/OrdDropZone';
import Stack from '@mui/material/Stack';
import { AntSwitch } from '../../src/helpers/constants';
import Typography from '@mui/material/Typography';
import { NewMedicine } from './NewMedicine';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../Hooks/useFetch';
import Loader from 'react-loader-spinner';
import { downloadBase64, isEmptyOrUndefined, message } from '../helpers/helpers';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useHistory } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';
import { customSwaltAlertAsistencial } from '../helpers';

export const Medicines = () => {
  let loader = (
    <div className='loading'>
      <Loader type='Oval' color='#003f80' height={100} width={100} />
    </div>
  );
  const animatedComponents = makeAnimated();
  const store = useSelector(state => state);
  const [hoverImg, setHoverImg] = useState({
    add: add,
  });
  const [trigger, setTrigger] = useState(0);
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [isSelected, setIsSelected] = useState({
    show: false,
    currentItem: {},
  });
  const token = store.loginReducer.Authorization;
  const [filters, setFilters] = useState('');
  const [excelFile, setExcelFile] = useState();
  const [isEditing, setIsEditing] = useState({
    showEdit: false,
    isCloning: false,
  });
  const [affectedItems, setAffectedItems] = useState([]);
  const [checkUpdt, setCheckUpdt] = useState(false);
  /* ----------------------------- FETCHS SECTION ----------------------------- */
  /* ----------------------------- GET ----------------------------- */
  const {
    results: chemicalSubstances,
    load: chemicalSubsLoader,
    trigger: getChemicalSubs,
  } = useGetMethod();
  const {
    results: excelTemplate,
    load: excelTemplateLoader,
    trigger: getExcelTemplate,
  } = useGetMethod();
  const {
    results: downladedTable,
    load: downladedTableLoader,
    trigger: getDowloadTable,
  } = useGetMethod();
  /* ---------------------------------- POST /PUT ---------------------------------- */
  const { load: medicineUpdateLoader, trigger: medicineUpdate } = usePostMethod();
  const { load: submmitExcelLoader, trigger: submmitExcel } = usePostMethod();
  /* ------------------------------------ - ----------------------------------- */
  const history = useHistory();
  const [excelData, setExcelData] = useState({
    showModal: false,
  });
  let formattedCompanies = () => {
    let arr = [];
    isSelected?.currentItem?.companies?.length > 0 &&
      isSelected?.currentItem?.companies?.forEach(e => {
        arr.push({ value: e.id, label: e.copyName });
      });
    return arr;
  };
  let formattedArticles = () => {
    let arr = [];
    isSelected?.currentItem?.articles?.length > 0 &&
      isSelected?.currentItem?.articles?.forEach(e => {
        arr.push({ value: e.id, label: e.name });
      });
    return arr;
  };
  let formattedAltMedicines = () => {
    let arr = [];
    isSelected?.currentItem?.alternatives?.length > 0 &&
      isSelected?.currentItem?.alternatives?.forEach(e => {
        arr.push({ value: e?.id, label: e.code + '-' + e.name });
      });
    return arr;
  };
  const biTableData = [
    {
      title: 'Nombre comercial',
      subTitle: isSelected?.currentItem?.tradeName || '-',
    },
    {
      title: 'CUM',
      subTitle: isSelected?.currentItem?.cum || '-',
    },
    // {
    //     title: "Código de verificación", //TODO: IF ONE DAY THEY ASK YOU FOR THE VERIFICATION CODE JUST UNCOMENT THIS
    //     subTitle: isSelected?.currentItem?.verificationCode || "-",
    // },
    {
      title: 'Forma farmacéutica',
      subTitle: isSelected?.currentItem?.pharmaceuticalForm || '-',
    },
    {
      title: 'Grupo farmacológico',
      subTitle: isSelected?.currentItem?.pharmaGroup || '-',
    },
    {
      title: 'Subgrupo farmaco',
      subTitle: isSelected?.currentItem?.pharmaSubgroup || '-',
    },
    {
      title: 'Disposición de medicamento',
      subTitle:
        isSelected?.currentItem?.internalUse === 0
          ? 'Dispensación'
          : isSelected?.currentItem?.internalUse === 1
            ? ' Uso interno'
            : isSelected?.currentItem?.internalUse === 2
              ? 'Ambos'
              : '-',
    },
    {
      title: 'Volumen',
      subTitle: isSelected?.currentItem?.qtyPerPresentation || '-',
    },
    {
      title: 'Unidad',
      subTitle: isSelected?.currentItem?.unitLabel || '-',
    },
    {
      title: 'Presentación',
      subTitle: isSelected?.currentItem?.presentationLabel || '-',
    },
    {
      title: 'Concentración',
      subTitle: isSelected?.currentItem?.concentration || '-',
    },
    {
      title: 'Sistema orgánico',
      subTitle: isSelected?.currentItem?.organicSystem || '-',
    },
    {
      title: 'Cobertura',
      subTitle: isSelected?.currentItem?.coverage || '-',
    },
    {
      title: 'INVIMA',
      subTitle: isSelected?.currentItem?.invima || '-',
    },
    {
      title: 'Indicaciones',
      subTitle: isSelected?.currentItem?.indications || '-',
    },
    {
      title: 'Diagnósticos',
      subTitle: isSelected?.currentItem?.diagnostics?.join(', ') || '-',
    },
    {
      title: 'Relacionado a compras',
      subTitle:
        isSelected?.currentItem?.articles?.length > 0
          ? isSelected?.currentItem?.articles.map((e, i) => {
              let last = isSelected?.currentItem?.articles.length; //2
              let str = i + 1 === last ? e.name : `${e.name}, `;
              return str;
            })
          : '-',
    },
    /* --------------------------------- MIPRES --------------------------------- */
  ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    getExcelTemplate({
      url: '/medical/params',
      objFilters: { paramKey: 'medicine_template' },
      token: token,
    });
    getDowloadTable({
      url: '/medical/medicine/template/',
      objFilters: { idAccount: idEnterprise },
      token: token,
    });
    getChemicalSubs({
      url: '/medical/medicine/',
      objFilters: { idAccount: idEnterprise, search: filters },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);
  useEffect(() => {
    !isEmptyOrUndefined(chemicalSubstances?.results) && formatData(chemicalSubstances.results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chemicalSubstances?.results]);

  const formatData = body => {
    let arrUpdatedMdc = [];
    if (affectedItems?.updated_medicines?.length > 0) {
      affectedItems?.updated_medicines.forEach(updt => {
        arrUpdatedMdc.push(updt?.id);
      });
    }
    let tempList = [];
    if (Array.isArray(body)) {
      body.forEach((item, index) => {
        let foundedUpdates = arrUpdatedMdc.find(x => x === item.id);
        /* ---------------------------------- GREEN --------------------------------- */
        let bgGreen =
          isSelected.currentItem?.id === item.id
            ? tableStyles.ordClearYellow
            : !isEmptyOrUndefined(foundedUpdates)
              ? tableStyles.ordGreenBG
              : '';
        let clearBgGreen =
          isSelected.currentItem?.id === item.id
            ? tableStyles.ordClearYellowOpacity20
            : !isEmptyOrUndefined(foundedUpdates)
              ? tableStyles.ordClearGreenBG
              : '';
        /* --------------------------------- YELLOW --------------------------------- */
        let bgYellow = isSelected.currentItem?.id === item.id && tableStyles.ordClearYellow;
        let bgYellowOpacity =
          isSelected.currentItem?.id === item.id && tableStyles.ordClearYellowOpacity20;

        let arrow = isSelected.currentItem?.id === item.id ? yellowRightArrow : ordRightArrow;
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className={`${bgYellow} ${bgGreen} text-start`}> &nbsp;{item.tradeName}</td>
            <td className={`${bgYellowOpacity} ${clearBgGreen} text-start`}>
              {' '}
              &nbsp; {item.presentationLabel}
            </td>
            {/* <td className={`${bgYellowOpacity} ${clearBgGreen} text-start`}> &nbsp;{item.coverage}</td> */}
            <td className={`${bgYellowOpacity} ${clearBgGreen} text-start`}>
              {' '}
              <>
                {' '}
                <img
                  alt={'felcha derecha'}
                  className={`cursorPointer`}
                  onClick={() => {
                    setIsSelected({
                      show: true,
                      currentItem: item,
                    });
                  }}
                  src={arrow}
                ></img>
                &nbsp;&nbsp;
              </>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };
  const [selectedBugged, setSelectedBugged] = useState([]);
  const changeBoxSelected = e => {
    let item = { ...e, id: e.cum + e.chemicalSubstance };
    let id = item?.id;
    let tempSelected = selectedBugged;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === id); // Bool
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== id); // Si lo encuentra eliminalo
      } else {
        tempSelected.push(item); // Sino Añadelo
      }
      setSelectedBugged(tempSelected);
    }
  };
  const renderTooltipAlert = item => <Tooltip>{item.error}</Tooltip>;
  const formatBuggedFiles = body => {
    let tempList = [];
    if (Array.isArray(body)) {
      body.forEach((item, index) => {
        // let backGround = item?.duplicate ? tableStyles.ordBgYellow : tableStyles.ordDisabledRow
        let backGround = '';
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className={`${backGround} text-center`}>&nbsp;{item.cum}</td>
            <td className={`${backGround} text-start`}> &nbsp; {item.mipresCode}</td>
            <td className={`${backGround} text-start`}> &nbsp;{item.chemicalSubstance}</td>
            <td className={`${backGround} text-start`}> &nbsp;{item.tradeName}</td>
            <td className={`${backGround} text-start`}> &nbsp;{item.error}</td>
            <td className={`${backGround} text-start`}>
              <>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipAlert(item)}
                >
                  <span>
                    &nbsp;{' '}
                    <img
                      width='20'
                      style={{
                        marginBottom: '12px',
                      }}
                      // className="mb-3"
                      alt='ordClearBlueX'
                      src={item.duplicate ? warningAlert : dangerAlert}
                    ></img>
                    &nbsp;
                  </span>
                </OverlayTrigger>
              </>
            </td>
            <td className={`${backGround} text-start`}>
              <>
                {/* &nbsp; */}
                {item?.duplicate && (
                  <input
                    key={index}
                    className='form-check-input styleCheckInputRatio mr-2 cursorPointer'
                    style={{ border: '2px solid #1a538d' }}
                    checked={selectedBugged.find(x => x === item.id)}
                    onChange={() => changeBoxSelected(item)}
                    type='checkbox'
                  ></input>
                )}
              </>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };
  const headers = [
    {
      title: 'ㅤNombre comercial',
    },
    {
      title: 'ㅤPresentación',
    },
    // {
    //     title: "Cobertura",
    // },
    {
      title: <>&nbsp;</>,
    },
  ];
  const buggedFilesHeaders = [
    {
      title: `CUM`,
      className: `text-center `,
    },
    {
      title: 'ㅤCódigo',
    },
    {
      title: 'ㅤNombre',
    },
    {
      title: 'Nombre comercial',
    },
    {
      title: 'Desc. del error',
    },
    {
      title: <>&nbsp;</>,
    },
    {
      title: <>&nbsp;</>,
    },
  ];
  let buttons = [
    {
      btnTitle: 'Cargar excel',
      btnVariant: 'clearBlue',
      btnIcon: <img className='pb-1' src={clearBlueUpload} alt='excel'></img>,
      action: () => setExcelData({ ...excelData, showModal: true }),
      btnTitleStyle: {
        fontSize: '14px',
        fontWeight: '700',
      },
    },
    {
      btnTitle: 'Descargar tabla',
      btnVariant: 'darkBlue',
      btnIcon: <img alt='Flecha abajo' src={darkBlueDownload}></img>,
      action: () =>
        downloadBase64(
          `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${downladedTable?.results?.split("'")[1]}`,
        ),
      btnTitleStyle: {
        fontSize: '14px',
        fontWeight: '700',
      },
    },
    {
      btnTitle: (
        <span
          onMouseEnter={() => setHoverImg({ ...hoverImg, add: AddBlue })}
          onMouseLeave={() => setHoverImg({ ...hoverImg, add: add })}
        >
          Nuevo medicamento &nbsp;
        </span>
      ),
      btnVariant: 'gray',
      btnSize: 4,
      action: () =>
        history.push({
          pathname: 'medicamentos/nuevo',
        }),
      // btnIcon: <img alt="Nuevo medicamento" src={hoverImg.add} className={tableStyles.circleAddButton} ></img>,
      btnIcon: (
        <img
          width='18'
          onMouseEnter={() => setHoverImg({ ...hoverImg, add: AddBlue })}
          onMouseLeave={() => setHoverImg({ ...hoverImg, add: add })}
          alt='icon'
          className='pb-1'
          src={hoverImg.add}
        ></img>
      ),
      btnTitleStyle: {
        fontSize: '14px',
        fontWeight: '700',
      },
    },
  ];
  const handleChangeStatus = () => {
    customSwaltAlertAsistencial({
      title: "¿Está seguro?",
      text: `Se ${isSelected?.currentItem?.status === 'disabled' ? 'habilitará' : 'Inhabilitará'} el estado del medicamento: ${isSelected?.currentItem?.tradeName || isSelected?.currentItem?.chemicalSubstance}`,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        medicineUpdate({
          url: '/medical/medicine/',
          token: token,
          doAfterSuccess: () => {
            setIsSelected({
              show: false,
              currentItem: {},
            });
            setTrigger(trigger + 1);
          },
          succesAction: () =>
            message('success', 'Actualizado exitosamente', `Se ha cambiado el estado del medicamento ${isSelected?.currentItem?.tradeName }`),
          method: 'PUT',
          body: {
            id: isSelected?.currentItem?.id,
            statusMed: isSelected?.currentItem?.status === 'disabled' ? 'enabled' : 'disabled',
          },
        });
      }
    }
    );
  };
  const handleFilter = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };
  const renderTooltipEdit = props => <Tooltip {...props}>Editar</Tooltip>;
  const renderTooltipClone = props => <Tooltip {...props}>Clonar</Tooltip>;
  const [buggedFiles, setBuggedFiles] = useState({
    showModal: false,
  });
  const handleSubmitExcel = () => {
    let base = !isEmptyOrUndefined(excelFile) && excelFile?.base64?.split(',');
    if (isEmptyOrUndefined(excelFile?.base64)) {
      return message('info', 'Campo vacíos', 'No se ha cargado ningún archivo');
    }
    submmitExcel({
      url: '/medical/medicine/template/',
      token: token,
      method: 'POST',
      body: {
        id_account: idEnterprise,
        file: base[1],
        updateExists: checkUpdt,
      },
      doAfterSuccess: result => {
        setAffectedItems(result.results);
        setTrigger(trigger + 1);
        setExcelData({ ...excelData, showModal: false });
      },
      succesAction: res => {
        Swal.fire({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: res?.message,
          showDenyButton: true,
          confirmButtonText: 'Aceptar',
          denyButtonText: `Don't save`,
        }).then(result => {
          if (result.isConfirmed) {
            if (res?.results?.error_medicines?.length > 0) {
              setBuggedFiles({ ...buggedFiles, showModal: true });
            }
          }
        });
      },
    });
  };
  const download = url => {
    window.location.href = url;
  };
  /* ---------------------------------- POST /PUT ---------------------------------- */
  const { load: createMedicineLoader, trigger: createMedicine } = usePostMethod();

  const handleCreateBugged = () => {
    if (selectedBugged.length <= 0) {
      return message(
        'info',
        'Medicamentos no seleccionados',
        'Para crear medicamentos debe seleccionar al menos uno',
      );
    }
    Swal.fire({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `
            Los medicamentos seleccionados ( ${selectedBugged.length}) se crearán con un dígito de verificación diferente`,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Si, Continuar',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        createMedicine({
          url: '/medical/medicine/create-multiple/',
          token: token,
          method: 'POST',
          body: selectedBugged,
          doAfterSuccess: () => {
            getChemicalSubs({
              url: '/medical/medicine/',
              objFilters: { idAccount: idEnterprise, search: filters },
              token: token,
            });
            setBuggedFiles({ ...buggedFiles, showModal: false });
          },
        });
      }
    });
  };
  // //console.log('%cMedicines.jsx line:522 isSelected.currentItem', 'color: #007acc;', isSelected.currentItem);
  return (
    <>
      {/* /* ----------------------------- Loader Section ----------------------------- */}
      {chemicalSubsLoader && loader}
      {medicineUpdateLoader && loader}
      {submmitExcelLoader && loader}
      {excelTemplateLoader && loader}
      {downladedTableLoader && loader}
      {createMedicineLoader && loader}
      {/* /* ------------------------------- EXCEL MODAL ------------------------------ */}
      <OrdModal
        titleClassName={`${tableStyles.ordDarkBlueText}`}
        title={'Cargar medicamentos'}
        show={excelData.showModal} // True/false
        btnYesName='Aceptar'
        btnNoName='Cancelar'
        btnYesEvent={() => handleSubmitExcel()}
        onHide={() => {
          setExcelData({ ...excelData, showModal: false });
          setExcelFile();
        }}
        btnNoEvent={() => {
          setExcelData({ ...excelData, showModal: false });
          setExcelFile();
        }}
      >
        <Row
          onClick={() =>
            excelTemplate?.results?.paramValue
              ? download(excelTemplate?.results?.paramValue)
              : () =>
                  message(
                    'info',
                    'Plantilla no encontrada',
                    'No se ha encontrado ninguna plantilla',
                  )
          }
          className={`${tableStyles.ordBgAquaMBlueOpacity} ${tableStyles.ordClearBlueText} ${tableStyles.clearBlueHover} rounded cursorPointer`}
        >
          <Col xs={12} className='py-1 rounded '>
            <b>
              Descargar la plantilla de excel &nbsp;
              <img src={ordDownload} alt={'Descargar excel'}></img>
            </b>
          </Col>
        </Row>
        <Row
          style={{ borderRadius: '15px' }}
          className={`${tableStyles.ordBgAquaMBlueOpacity} ${tableStyles.ordClearBlueText} mt-4`}
        >
          <Col xs={12} className={`${tableStyles.ordDashedBorder} ${tableStyles.clearBlueHover}`}>
            <OrdDropZone
              whiteExtList={['xls', 'xlsx']}
              getFile={e => setExcelFile(e)}
            ></OrdDropZone>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={12} className={`d-flex justify-content-between`}>
            <h6 className={`${tableStyles.ordDarkGrayText}`}>
              <input
                className='form-check-input styleCheckInputRatio mr-2 cursorPointer'
                checked={checkUpdt}
                onChange={e => setCheckUpdt(e.target.checked)}
                style={{ border: '2px solid #1a538d' }}
                type='checkbox'
              ></input>
              <b className={tableStyles.f16}>&nbsp;Actualizar medicamentos existentes</b>
            </h6>
          </Col>
          <Col className='' xs={12}>
            <p className={`${tableStyles.ordDarkGrayText} ${tableStyles.f12} pl-3 ml-3 px-3`}>
              Si alguno de los medicamentos que importes ya existen en el sistema, se actualizará
              automáticamente.
            </p>
          </Col>
        </Row>
      </OrdModal>
      {/* /* ------------------------------- BUGGED FILES MODAL  ------------------------------ */}
      <OrdModal
        titleClassName={`${tableStyles.ordOrangeText}`}
        title={'Resumen de medicamentos no cargados'}
        show={buggedFiles.showModal}
        btnYesName='Enviar'
        btnNoName='Cancelar'
        size={'700'}
        btnYesEvent={() => handleCreateBugged()}
        onHide={() => {
          setBuggedFiles({ ...buggedFiles, showModal: false });
          setExcelFile();
        }}
        btnNoEvent={() => {
          setBuggedFiles({ ...buggedFiles, showModal: false });
          setExcelFile();
        }}
      >
        {/* /* --------------------------- BUGGED FILES TABLE --------------------------- */}
        <OrdTable headers={buggedFilesHeaders} hasChildren={true}>
          {formatBuggedFiles(affectedItems?.error_medicines)}
        </OrdTable>
      </OrdModal>

      <OrdGenericTemplate buttons={buttons} showBottomBorder={true} title={'Medicamentos'}>
        <Row style={{height:'70vh', overflow: 'hidden'}}>
          <Col
            xs={4}
            className={`${tableStyles.scrollThis} ${tableStyles.ordClearBlueRighBorder}`}
            style={{ height: '100%' }}
          >
            <Row>
              <Col xs={12}>
                <form onSubmit={e => handleFilter(e)}>
                  <Form.Group className='mb-3 text-start' controlId='search'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
                    <div className={`d-flex ${tableStyles.ordGrayInputSearch}`}>
                      <Form.Control
                        className={`ord-roundInput-search`}
                        type='text'
                        onChange={e => setFilters(e.target.value)}
                        placeholder='Escribe aquí para buscar'
                      />
                      <img alt='buscar' src={ordBlueSearch} className='pointer' onClick={() => setTrigger(trigger + 1)}></img>
                    </div>
                  </Form.Group>
                </form>
              </Col>
            </Row>
            <OrdTable headers={headers} hasChildren={true} fTdClearBlue={true}>
              {formatData(chemicalSubstances.results)}
            </OrdTable>
          </Col>
          <Col xs={8} className={`${tableStyles.scrollThis}`} style={{ height: '100%' }}>
            {isSelected.show ? (
              <>
                <Row className={`mb-3 mt-4`}>
                  <Col xs={8}>
                    <h4 className={`${tableStyles.ordSubTitleStyle}`}>
                      {isSelected?.currentItem?.chemicalSubstance || '-'}
                    </h4>
                  </Col>
                  <Col xs={4} className=' d-flex justify-content-end pr-2 '>
                    <div className='d-flex  w-100 flex-row-reverse'>
                      <Col xs={6}>
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <AntSwitch
                            defaultChecked
                            key={trigger + 'Ant'}
                            checked={isSelected?.currentItem?.status === 'disabled' ? false : true}
                            onChange={e => handleChangeStatus(e.target.checked)}
                            inputProps={{ 'aria-label': 'ant design' }}
                          />
                          <Typography key={trigger + 'AntCheck'} className={`text-secondary`}>
                            {isSelected?.currentItem?.status === 'disabled'
                              ? 'Inhabilitado'
                              : 'Habilitado'}
                          </Typography>
                        </Stack>
                      </Col>
                      <Col
                        onClick={() => {
                          setIsSelected({ ...isSelected, show: false });
                          setIsEditing({
                            ...isEditing,
                            showEdit: true,
                            data: isSelected.currentItem,
                            isCloning: false,
                          });
                        }}
                        className={`cursorPointer`}
                        xs={1}
                      >
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipEdit}
                        >
                          <span>
                            <img
                              className={`${tableStyles.editBlueHover}`}
                              alt='Editar'
                              src={penFullGray}
                            ></img>
                          </span>
                        </OverlayTrigger>
                      </Col>
                      <Col
                        xs={1}
                        onClick={() => {
                          setIsSelected({ ...isSelected, show: false });
                          setIsEditing({
                            ...isEditing,
                            showEdit: true,
                            data: isSelected.currentItem,
                            isCloning: true,
                          });
                        }}
                      >
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipClone}
                        >
                          <span>
                            <img
                              alt='Clonar'
                              className={`${tableStyles.copyBlueHover} cursorPointer`}
                              src={grayClone}
                            ></img>
                          </span>
                        </OverlayTrigger>
                      </Col>
                    </div>
                  </Col>
                  <OrdBiTable data={biTableData} />
                  <Col xs={11} className='mb-3'>
                    <p className='pt-2 pl-3 m-0'>
                      <span className={`${tableStyles.ordSubTitleStyle}`}>
                        {' '}
                        &nbsp; &nbsp;Artículos relacionados
                      </span>
                    </p>
                    <Select
                      isMulti
                      value={formattedArticles()}
                      isDisabled
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      className='text-secondary'
                      placeholder={'No hay artículos relacionados'}
                      styles={customSelectBockedYellow}
                    ></Select>
                  </Col>
                  <Col xs={11} className='mb-3'>
                    <p className='pt-2 pl-3 m-0'>
                      <span className={`${tableStyles.ordSubTitleStyle}`}>
                        {' '}
                        &nbsp;Empresas asignadas
                      </span>
                    </p>
                    <Select
                      isMulti
                      value={formattedCompanies()}
                      isDisabled
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      className='text-secondary'
                      placeholder={'No hay empresas asignadas'}
                      styles={customSelectBockedYellow}
                    ></Select>
                  </Col>
                  <Col xs={11} className='mb-3'>
                    <p className='pt-2 pl-3 m-0'>
                      <span className={`${tableStyles.ordSubTitleStyle}`}>
                        {' '}
                        &nbsp;Medicamentos alternativos
                      </span>
                    </p>
                    <Select
                      isMulti
                      value={formattedAltMedicines()}
                      isDisabled
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      className='text-secondary'
                      placeholder={'Sin medicamentos'}
                      styles={customSelectBockedYellow}
                    ></Select>
                    {/* } */}
                  </Col>
                </Row>
              </>
            ) : isEditing.showEdit ? (
              <NewMedicine
                update={() => setTrigger(trigger + 1)}
                isEditing={true}
                isCloning={isEditing.isCloning}
                medicineData={isEditing.data}
                selectedItem={isSelected.currentItem}
                setBackSelected={res => setIsSelected({ ...isSelected, currentItem: { id: res } })}
                detail={isEditing}
                onHide={() => {
                  setIsEditing({ ...isEditing, showEdit: false });
                }}
              ></NewMedicine>
            ) : (
              <section className={`d-flex justify-content-center`} style={{ padding: '30%' }}>
                <div>
                  <Typography className={`text-secondary`}>
                    <h5>Selecciona un medicamento</h5>
                  </Typography>

                  <img className={'center'} width={'30px'} src={ordGrayBack} alt='Atrás'></img>
                </div>
              </section>
            )}
          </Col>
        </Row>
      </OrdGenericTemplate>
    </>
  );
};
