import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from 'react-js-pagination';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import tableStyles from '../../../../../src/OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import styles from '../../../../../src/components/Layouts/tableStyle.module.scss';
import { convertMoneyFormatNew } from '../../../../helpers';

const StyledTableCustom = styled.div`
  padding: 20px;
  margin: ${({ margin }) => (margin ? margin : '')};
  width: ${({ width }) => (width ? width : '85%')};
`;

export const TableCustom = ({
  headers = [],
  body = [],
  colorHeader,
  fontWeightHeader,
  textAlignHeader,
  iconPath,
  width,
  paginate,
  extraFooter,
  totalBalance = 0,
}) => {
  const [sizeHeadertable, setSizeHeadertable] = useState('');
  const { colors } = useTheme();

  const history = useHistory();

  useEffect(() => {
    setSizeHeadertable('');
    headers.map(el => setSizeHeadertable(state => `${state} ${el.width}`));
  }, [headers]);

  return (
    <StyledTableCustom className={`animate__animated animate__fadeIn`} width={width}>
      <Box
        display={'grid'}
        gridTemplateColumns={sizeHeadertable}
        marginBottom={'0.7rem'}
        padding='0px 15px'
        gap={2}
      >
        {headers.map(el => (
          <Box
            color={colorHeader ? colorHeader : colors.darkBlueInput}
            fontWeight={fontWeightHeader ? fontWeightHeader : 'bold'}
            textAlign={textAlignHeader ? textAlignHeader : 'normal'}
            key={el.id}
            className={el?.className || ''}
          >
            {el[Object.keys(el)[0]]}
          </Box>
        ))}
      </Box>

      {body.map(elBody => (
        <>
          {elBody.active ? (
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 250 }}
              overlay={
                <Tooltip>
                  <span className={styles.ordDarkGrayText}>Realizar cierre parcial</span>
                </Tooltip>
              }
            >
              <Box
                marginBottom={'0.7rem'}
                display={'grid'}
                style={{ transition: 'all 0.4s', padding: '7px 15px', borderRadius: '15px' }}
                gridTemplateColumns={sizeHeadertable}
                gap={2}
                sx={
                  elBody.active
                    ? {
                        backgroundColor: 'white',
                        boxShadow: `0px 0px 8px ${colors.darkGray2}`,
                        '&:hover': {
                          boxShadow: `0px 0px 0px ${colors.darkGray2}`,
                          background: `${colors.tableEven}`,
                          cursor: 'pointer',
                        },
                      }
                    : { background: `${colors.ordTableHeadBgColor}` }
                }
                onClick={() => {
                  const totalAmount =
                    Number(
                      elBody?.totalAmount
                        ?.replaceAll('.', '')
                        ?.replaceAll(',', '')
                        ?.replaceAll('$', '')
                        ?.replace('DOP', '')
                    ) + (totalBalance ?? 0);

                  history.push({
                    pathname: '/admision/detalleCierreDiario',
                    state: {
                      totalAmount: convertMoneyFormatNew({
                        textNumber: String(totalAmount),
                        convertNumber: true,
                      }),
                    },
                  });
                }}
              >
                {headers.map((el, i) => (
                  <Box
                    className={el?.className || ''}
                    fontWeight={fontWeightHeader ? fontWeightHeader : 'bold'}
                    textAlign={textAlignHeader ? textAlignHeader : 'normal'}
                    key={el.id}
                    style={
                      i === 0
                        ? { color: colors.ordDarkGray, fontWeight: 'bold' }
                        : { color: colors.ordDarkGray, fontWeight: 'lighter' }
                    }
                  >
                    {headers.length - 1 === i ? (
                      <Box display={'grid'} gridTemplateColumns='2fr 1fr'>
                        {elBody[Object.keys(el)[0]] || ''}
                        <div>&nbsp;</div>
                      </Box>
                    ) : (
                      elBody[Object.keys(el)[0]] || ''
                    )}

                    {/* {elBody[Object.keys(el)[0]]} */}
                  </Box>
                ))}
              </Box>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 250 }}
              overlay={
                <Tooltip>
                  <span className={styles.ordDarkGrayText}>Ver detalle de cierre parcial</span>
                </Tooltip>
              }
            >
              <Box
                marginBottom={'0.7rem'}
                display={'grid'}
                gap={2}
                style={{ transition: 'all 0.4s', padding: '7px 15px', borderRadius: '15px' }}
                gridTemplateColumns={sizeHeadertable}
                onClick={() => {
                    // onDrawerDetail(elBody.cashClosingId)
                    history.push({
                      pathname: '/admision/detalleCierreDiario',
                      search: `?cashClosingId=${elBody.cashClosingId}`,
                      state: {}
                    });
                  }
                }
                sx={{
                  background: `${colors.ordTableHeadBgColor}`,
                  boxShadow: `0px 0px 8px ${colors.darkGray2}`,
                  '&:hover': {
                    boxShadow: `0px 0px 0px ${colors.darkGray2}`,
                    background: `${colors.tableEven}`,
                    cursor: 'pointer',
                  },
               }}
              >
                {headers.map((el, i) => (
                  <Box
                    className={el?.className || ''}
                    fontWeight={fontWeightHeader ? fontWeightHeader : 'bold'}
                    textAlign={textAlignHeader ? textAlignHeader : 'normal'}
                    key={el.id}
                    style={
                      i === 0
                        ? { color: colors.ordDarkGray, fontWeight: 'bold' }
                        : { color: colors.ordDarkGray, fontWeight: 'lighter' }
                    }
                    // sx={elBody.active ? { borderRight: `1px solid ${colors.darkGray2}` } : { borderRight: `1px solid ${colors.ordAquaMarine}` }}
                  >
                    {headers.length - 1 === i ? (
                      <Box display={'grid'} gridTemplateColumns='2fr 1fr'>
                        {elBody[Object.keys(el)[0]] || ''}
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 250 }}
                          className={styles.app_container_tool_websc}
                          overlay={
                            <Tooltip>
                              <span className={styles.ordDarkGrayText}>Cerrado</span>
                            </Tooltip>
                          }
                        >
                          <img style={{ marginLeft: 'auto' }} src={iconPath} alt={'img'} />
                        </OverlayTrigger>
                      </Box>
                    ) : (
                      elBody[Object.keys(el)[0]] || ''
                    )}
                  </Box>
                ))}
              </Box>
            </OverlayTrigger>
          )}
        </>
      ))}

      {extraFooter && extraFooter}

      {paginate && (
        <div className={`${tableStyles.wrapper}`}>
          {paginate?.showTextDetails && (
            <>
              <p className={`${tableStyles.paginationText} text-secondary`}>
                Pag. {paginate?.activePage || ''}
                {' de '}
                {Math.ceil(paginate?.totalPages / paginate?.perPage)
                  ? Math.ceil(paginate?.totalPages / paginate?.perPage)
                  : ''}
                {' - '}
                {paginate?.totalPages} encontrados
              </p>
            </>
          )}
          <Pagination
            activePage={paginate?.activePage}
            itemsCountPerPage={paginate?.perPage}
            totalItemsCount={paginate?.totalPages}
            pageRangeDisplayed={paginate?.pageRangeDisplayed || 5}
            onChange={e => paginate?.onChangePage(e)}
            itemClassPrev={tableStyles.itemClassPrev}
            itemClassNext={tableStyles.itemClassNext}
            itemClassFirst={tableStyles.itemClassFirst}
            itemClassLast={tableStyles.itemClassLast}
            itemClass={tableStyles.itemClass}
          />
        </div>
      )}
    </StyledTableCustom>
  );
};
