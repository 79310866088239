import { Col, Form, Row } from "react-bootstrap"
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";
import { isEmptyOrUndefined } from "../../../helpers";
import { formatNumberWithoutDecimals } from "../../../helpers/numberFormatting";

export const CardTotal = ({ valuesPay, totalBalance, outstandingBalance, currency }) => {
    return (
        <Row className="">
            <div className="">
                <div className={`d-flex justify-content-end mb-2 ${tableStyles.addBorderLineGray} py-2`}>

                    {
                        !isEmptyOrUndefined(totalBalance) && (
                            <Col xs={3} className="d-flex gap-4">
                                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                    <span className="text-start">&nbsp;Saldo a favor</span>
                                </Form.Label>

                                <Form.Label className={`m-0 ${tableStyles.f14}`} style={{ color: '#00A551' }}>
                                    {formatNumberWithoutDecimals(totalBalance || 0, currency)}
                                </Form.Label>
                            </Col>
                        )
                    }

                    <Col xs={3} className="d-flex gap-4">
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                            <span className="text-start">&nbsp;Saldo pendiente</span>
                        </Form.Label>

                        <Form.Label className={`m-0 ${tableStyles.f14} ${tableStyles.orangeLightText}`}>
                            {formatNumberWithoutDecimals(outstandingBalance || 0, currency)}
                        </Form.Label>
                    </Col>

                    <Col xs={2}>
                        <div className="d-flex flex-column">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span className="text-start">&nbsp;Valor Servicio</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span className="text-start">&nbsp;Descuento</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span className="text-start">&nbsp;Anticipos</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span className="text-start">&nbsp;Cobertura</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <span className="text-start">&nbsp;Pago a crédito</span>
                            </Form.Label>
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <b className="text-start">&nbsp;Total a pagar</b>
                            </Form.Label>
                        </div>
                    </Col>

                    <Col xs={1}>
                        <div className="d-flex flex-column">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                {formatNumberWithoutDecimals(valuesPay.price, currency)}
                            </Form.Label>

                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                {formatNumberWithoutDecimals(valuesPay.discount, currency)}
                            </Form.Label>

                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                {formatNumberWithoutDecimals(valuesPay.advance, currency)}
                            </Form.Label>
                            
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                {formatNumberWithoutDecimals(valuesPay.coverage, currency)}
                            </Form.Label>

                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                {formatNumberWithoutDecimals(valuesPay.creditAmount, currency)}
                            </Form.Label>
                            
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                                <b>{formatNumberWithoutDecimals(valuesPay.remainingAmount, currency)}</b>
                            </Form.Label>
                        </div>
                    </Col>
                </div>
            </div>
        </Row>
    )
}