import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod, usePostMethod } from '../../../Hooks/useFetch';
import { ACTIVE_ID } from '../../../actions/actionTypes';
import { city_get, province_get } from '../../../actions/locationActions';
import closeInsurer from '../../../assets/img/icons/close_insurer_voluntary.svg';
import { appCustomSelectNew } from '../../../components/Layouts/react-select-custom';
import styles from '../../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlertAsistencial, formatteDocument } from '../../../helpers';
import { genderList, maritalStatusList } from '../../../helpers/constants';
import {
    expRegList,
    formatToRcSelect,
    generateId,
    getAge,
    loader,
    message,
    validExpReg,
    validateEmptyString,
} from '../../../helpers/helpers';
import { companyTypeTags } from '../../../constants';

const HandlePatient = ({
    options,
    filters,
    trigger,
    setTrigger,
    setOptions,
    getListPacient,
    getDetailPatient,
    postTicketFunction,
    setPatient,
    detailPatientData,
}) => {
    const dispatch = useDispatch();
    const store = useSelector(state => state);
    const token = store.loginReducer.Authorization;
    const idEnterprise = store.loginReducer.currentAccount.id;
    const [newPatient, setNewPatient] = useState({
        docTypeId: '',
        document:
            validExpReg(filters.search, expRegList.number) === true ? filters.search : '',
        firstName: '',
        otherNames: '',
        lastName: '',
        secondSurname: '',
        gender: '',
        birthDate: '',
        homeAddress: '',
        email: '',
        cityId: '',
        homephone: '',
        cellphone: '',
        whatsapp: '',
        bloodType: '',
        maritalStatus: '',
        provinceId: '',
        contracts: [],
        affiliationType: '',
        affiliationTypeId: '',
        affiliationTypeName: '',
    });
    const [loading, setLoading] = useState(false);
    const [contracts, setContracts] = useState([
        {
            index: generateId(),
            contractId: '',
            corporateClientId: '',
            companyTypeTag: 'eps',
            populationId: '',
            crtId: '',
            policy: '',
            isMain: 1,
            isEnabled: 1,
        },
    ]);
    const bloodType = [
        { value: 'A+', label: 'A+' },
        { value: 'A-', label: 'A-' },
        { value: 'B+', label: 'B+' },
        { value: 'B-', label: 'B-' },
        { value: 'AB+', label: 'AB+' },
        { value: 'AB-', label: 'AB-' },
        { value: 'O+', label: 'O+' },
        { value: 'O-', label: 'O-' },
    ];
    const [provincesOptions, setProvincesOptions] = useState([
        { value: '', label: 'Seleccionar...', name: 'id_dep' },
    ]);
    const [citiesOptions, setCitiesOptions] = useState([
        { value: '', label: 'Seleccionar...', name: 'id_province' },
    ]);

    const { results: typeDocument, trigger: getDocumentTypes } = useGetMethod();
    const { results: insurersObligatory, trigger: getInsurersObligatory } = useGetMethod();
    const {
        results: companyTypesResults,
        load: companyTypesLoader,
        trigger: getCompanyTypes,
    } = useGetMethod();
    const {
        results: listAffiliationTypes,
        load: loaderListAffiliationTypes,
        trigger: getListAffiliationTypes,
    } = useGetMethod();
    const { trigger: getValidateExistence } = useGetMethod();
    const { load: loadCreatePatient, trigger: sendPatient } = usePostMethod();

    const getSelectCities = useCallback(
        id => {
            setLoading(true);
            dispatch(city_get({ province: id }, (data) => {
                if (Array.isArray(data)) {
                    const tempArray = data.map(item => ({
                        value: item.id,
                        label: item.name,
                        name: 'id_province',
                    }));
                    setCitiesOptions([{ value: '', label: 'Seleccionar...', name: 'id_province' }, ...tempArray]);
                }
                setLoading(false);
            }));
            setNewPatient(state => ({ ...state, provinceId: id }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch],
    );

    useEffect(() => {
        dispatch(
            province_get({
                entity_account: store.loginReducer.currentAccount.id,
            }, ({ data }) => {
                if (Array.isArray(data)) {
                    const tempArray = data.map(item => ({
                        value: item.id,
                        label: item.name,
                        name: 'id_province',
                    }));
                    setProvincesOptions([{ value: '', label: 'Seleccionar...', name: 'id_dep' }, ...tempArray]);
                }
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, store.loginReducer.currentAccount.id]);


    useEffect(() => {
        getSelectCities(newPatient.provinceId);
    }, [newPatient.provinceId, getSelectCities]);


    useEffect(() => {
        if (detailPatientData?.results && options.isEditingPatient) {
            const hasMainContract = detailPatientData?.results?.contracts?.some(contract => contract?.isMain === 1);
            if (!hasMainContract) {
                const newMainContract = {
                    index: generateId(),
                    contractId: '',
                    corporateClientId: '',
                    companyTypeTag: 'eps',
                    populationId: '',
                    crtId: '',
                    policy: '',
                    isMain: 1,
                    isEnabled: 1,
                };
                detailPatientData?.results?.contracts?.push(newMainContract);
            }
            const contractsSort = [...detailPatientData.results.contracts].sort((a, b) => b.isMain - a.isMain);


            setNewPatient({
                ...detailPatientData.results,
                documentType: detailPatientData?.results?.docTypeId,
                document:
                    validExpReg(filters.search, expRegList.number) === true
                        ? filters.search
                        : detailPatientData?.results?.document,
                firstName: detailPatientData?.results?.firstName,
                otherNames: detailPatientData?.results?.otherNames,
                lastName: detailPatientData?.results?.lastName,
                secondSurname: detailPatientData?.results?.secondSurname,
                gender: detailPatientData?.results?.gender,
                birthDate: detailPatientData?.results?.birthDate,
                homeAddress: detailPatientData?.results?.homeAddress,
                email: detailPatientData?.results?.email,
                cityId: detailPatientData?.results?.cityId,
                homephone: detailPatientData?.results?.homephone,
                cellphone: detailPatientData?.results?.cellphone,
                whatsapp: detailPatientData?.results?.whatsapp,
                bloodType: detailPatientData?.results?.bloodType,
                maritalStatus: detailPatientData?.results?.maritalStatus,
                provinceId: detailPatientData?.results?.provinceId,
                contracts: detailPatientData?.results?.contracts,
                affiliationType: detailPatientData?.results?.affiliationTypeId,
                affiliationTypeId: detailPatientData?.results?.affiliationTypeId,
                affiliationTypeName: detailPatientData?.results?.affiliationTypeName,
            });

            setContracts(
                detailPatientData?.results?.contracts?.length > 0
                    ? contractsSort
                    : [
                        {
                            index: generateId(),
                            contractId: '',
                            corporateClientId: '',
                            companyTypeTag: 'eps',
                            populationId: '',
                            crtId: '',
                            policy: '',
                            isMain: 1,
                            isEnabled: 1,
                        },
                    ],
            );
        }
    }, [detailPatientData?.results, filters.search, options.isEditingPatient]);

    useEffect(() => {
        void getDocumentTypes({
            url: '/payroll/document_types/',
            token: token,
        });

        void getInsurersObligatory({
            url: '/medical/client/',
            token: token,
            objFilters: { contractCompanyType: 'eps', status: 'enabled', eaccount: idEnterprise },
        });

        void getListAffiliationTypes({
            url: '/medical/generals/affiliation-types/',
            objFilters: { eaccount: idEnterprise },
            token: token,
        });

        void getCompanyTypes({
            url: '/medical/client/companytype/',
            token: token,
        });
    }, [
        getDocumentTypes,
        getInsurersObligatory,
        getListAffiliationTypes,
        getCompanyTypes,
        token,
        idEnterprise,
    ]);

    useEffect(() => {
        const affiliationType = newPatient?.affiliationTypeId
            ? listAffiliationTypes?.results
                ?.filter(x => x.affiliationTypeId === newPatient.affiliationTypeId)
                ?.map(el => ({
                    label: el?.affiliationTypeName,
                    value: el?.affiliationTypeId,
                }))?.[0]
            : {
                label: listAffiliationTypes?.results?.at(0)?.affiliationTypeName,
                value: listAffiliationTypes?.results?.at(0)?.affiliationTypeId,
            };
        if (affiliationType?.value) {
            setNewPatient(states => ({
                ...states, affiliationTypeName: affiliationType.label,
                affiliationTypeId: affiliationType.value,
                affiliationType: affiliationType.value,
            }))
        }
    }, [
        listAffiliationTypes?.results,
        newPatient?.affiliationTypeId
    ]);

    const formatTypeDocument = formatToRcSelect(typeDocument.results, 'id', 'description', 'prefix');

    const optionsEntityObligatory = formatToRcSelect(insurersObligatory.results, 'id', 'name');

    const companyTypeOptions = formatToRcSelect(companyTypesResults?.results, 'id', 'name', 'tag');

    const addInsurer = () => {
        let newInsurer = {
            index: generateId(),
            contractId: '',
            corporateClientId: '',
            companyTypeTag: '',
            populationId: '',
            crtId: '',
            policy: '',
            isMain: 0,
            isEnabled: 1,
        };
        setContracts([...contracts, newInsurer]);
    };

    const onSubmitPatient = e => {
        const validations = validateEmptyString([
            { field: newPatient.docTypeId, message: 'El tipo de documento es obligatorio' },
            { field: newPatient.document, message: 'El número de documento es obligatorio' },
            { field: newPatient.firstName, message: 'El primer nombre es obligatorio' },
            { field: newPatient.lastName, message: 'El primer apellido es obligatorio' },
            { field: newPatient.birthDate, message: 'La fecha de nacimiento es obligatorio' },
            { field: newPatient.whatsapp, message: 'El Whatsapp es obligatorio' },
            { field: newPatient.gender, message: 'El género es obligatorio' },
            { field: newPatient.affiliationTypeId, message: 'El Tipo de afiliado es obligatorio' },
            { field: newPatient.cellphone, message: 'El celular es obligatorio' },
        ]);
        const contractsVoluntary = contracts.filter(e => e.isMain === 0);
        const contractObligatory = contracts.find(e => e.isMain === 1);
        const existParticular = contractsVoluntary.some(item => item.companyTypeTag === 'particular');

        const validationContractsObligatory = () => {
            if (
                contractObligatory?.crtId !== '' &&
                contractObligatory.contractId !== '' &&
                contractObligatory.corporateClientId !== '' &&
                contractObligatory.populationId !== ''
            ) {
                return true;
            } else {
                return false;
            }
        };
        const validationContractsParticular = contractsVoluntary.some(
            item =>
                item.companyTypeTag === 'particular' &&
                (item.contractId === '' || item.corporateClientId === '' || item.populationId === ''),
        );
        const isEmptyVoluntary = contractsVoluntary.some(
            item =>
                item.companyTypeTag === 'prepaid' &&
                (item.companyTypeTag === '' ||
                    item.contractId === '' ||
                    item.corporateClientId === '' ||
                    item.policy === ''),
        );
        let status;


        if (!existParticular) {
            if (validationContractsObligatory()) {
                status = true;
                if (isEmptyVoluntary) {
                    return message(
                        'warning',
                        'Llenar campos aseguradora voluntaria',
                        'Para crear una aseguradora voluntaria es necesario llenar todos los campos',
                        undefined,
                        true,
                    );
                }
            } else {
                status = false;
                return message(
                    'warning',
                    'Debe crear la aseguradora obligatoria',
                    'El anterior procedimiento es pre-requisito para crear una aseguradora voluntaria',
                    undefined,
                    true,
                );
            }
            status = true;
            if (isEmptyVoluntary) {
                return message(
                    'warning',
                    'Llenar campos aseguradora voluntaria',
                    'Para crear una aseguradora voluntaria es necesario llenar todos los campos',
                    undefined,
                    true,
                );
            }
        } else {
            status = true;
            if (validationContractsParticular) {
                status = false;
                return message(
                    'warning',
                    'Llenar campos aseguradora particular',
                    'Para crear una aseguradora particular es necesario llenar todos los campos',
                    undefined,
                    true,
                );
            }

            if (isEmptyVoluntary) {
                status = false;
                return message(
                    'warning',
                    'Llenar campos aseguradora voluntaria',
                    'Para crear una aseguradora voluntaria es necesario llenar todos los campos',
                    undefined,
                    true,
                );
            }
        }

        const emailRegex = /^\S+@\S+\.\S+$/;
        if (newPatient.email.length && !emailRegex.test(newPatient.email)) {
            return customSwaltAlertAsistencial({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Debe ingresar un correo electrónico válido',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
            });
        }

        if (validations.valid && status) {
            e.preventDefault();
            const message = options.isEditingPatient
                ? `Se editará el paciente: ${newPatient.firstName} ${newPatient.otherNames} ${newPatient.lastName} ${newPatient.secondSurname}`
                : `Se creará el paciente ${newPatient.firstName} ${newPatient.otherNames} ${newPatient.lastName} ${newPatient.secondSurname}`;

            const body = options.isEditingPatient
                ? {
                    ...newPatient,
                    contracts: existParticular && !validationContractsObligatory() ?
                        contracts.filter(con => con.isMain === 0) :
                        contracts,
                    eaccount: idEnterprise,
                }
                : {
                    ...newPatient,
                    documentType: newPatient.docTypeId,
                    phoneNumber: newPatient.cellphone,
                    documentNumber:
                        validExpReg(filters.search, expRegList.number) === true
                            ? filters.search
                            : newPatient.document,
                    secondName: newPatient.otherNames,
                    firstSurname: newPatient.lastName,
                    contracts: existParticular && !validationContractsObligatory() ?
                        contracts.filter(con => con.isMain === 0) :
                        contracts,
                    eaccount: idEnterprise,
                };

            customSwaltAlertAsistencial({
                title: `¿Está seguro?`,
                text: message,
                confirmButtonText: `Si, continuar`,
                cancelButtonText: `Cancelar`,
                showCancelButton: true,
            }).then(result => {
                if (result?.isConfirmed) {
                    sendPatient({
                        url: options.isEditingPatient ? '/medical/patient/' : '/medical/patient/register/',
                        token: token,
                        method: options.isEditingPatient ? 'PUT' : 'POST',
                        body: body,
                        doAfterSuccess: data => {
                            postTicketFunction('save_new_patient');
                            getListPacient({
                                url: '/medical/patient/patients-list',
                                objFilters: filters,
                                token: token,
                            });

                            setPatient(patient => ({ ...patient, id: data.results.id }));
                            getDetailPatient({
                                url: '/medical/patient/',
                                objFilters: { id: data.results.id, eaccount: idEnterprise },
                                token: token,
                            });

                            dispatch({
                                type: ACTIVE_ID,
                                payload: data.results.id,
                            });

                            if (options.isEditingPatient) {
                                setOptions({
                                    ...options,
                                    isEditingPatient: false,
                                    showHandlePatient: false,
                                    showCards: true,
                                    showImportance: false,
                                    showInfoPatient: false,
                                    showSearchPatient: false,
                                    showDetailPatient: true,
                                    showEditPatient: false,
                                    showProgramming: !options.showProgramming ? false : true,
                                    showSchedule: false,
                                    showRegisterPatient: false,
                                });
                            } else {
                                setOptions({
                                    ...options,
                                    showInfoPatient: false,
                                    showDetailPatient: true,
                                    showSearchPatient: false,
                                    showHandlePatient: false,
                                });
                            }
                        },
                    });
                }
            });
        }
    };

    const renderAditionalInformation = () => {
        return (
            <div style={{ marginBottom: '10px' }}>
                <div>
                    <span className={styles.app_subtitle_register_app}>Información complementaria</span>
                </div>
                <div className={styles.app_container_form_register}>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='toto'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Provincia </b>
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={provincesOptions}
                                className='text-secondary'
                                placeholder={'Seleccionar...'}
                                styles={appCustomSelectNew}
                                value={provincesOptions.find(x => x.value === newPatient.provinceId)}
                                onChange={e => {
                                    setNewPatient({ ...newPatient, provinceId: e.value, cityId: '' });
                                    getSelectCities(e.value);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='to'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Municipio </b>
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                value={citiesOptions.find(x => x.value === newPatient.cityId)}
                                options={citiesOptions}
                                className='text-secondary'
                                onChange={e => {
                                    setNewPatient({
                                        ...newPatient,
                                        cityId: e.value,
                                    });
                                }}
                                placeholder={'Seleccionar...'}
                                styles={appCustomSelectNew}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='tu'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Dirección </b>
                            </Form.Label>
                            <Form.Control
                                className={styles.app_inputs_detail_patient}
                                type='text'
                                value={newPatient.homeAddress}
                                onChange={e => {
                                    setNewPatient({
                                        ...newPatient,
                                        homeAddress: e.target.value,
                                    });
                                }}
                                placeholder='Escribe...'
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='vip'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Grupo sanguíneo </b>
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={bloodType}
                                className='text-secondary'
                                placeholder={'Seleccionar...'}
                                value={bloodType.find(x => x.value === newPatient.bloodType)}
                                styles={appCustomSelectNew}
                                onChange={e => {
                                    setNewPatient({ ...newPatient, bloodType: e.value });
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='self'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>
                                    Celular <span style={{ color: 'red' }}>*</span>
                                </b>
                            </Form.Label>
                            <Form.Control
                                className={styles.app_inputs_detail_patient}
                                type='text'
                                placeholder='Escribe...'
                                value={newPatient.cellphone}
                                onChange={e => {
                                    setNewPatient({
                                        ...newPatient,
                                        cellphone: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='chis'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Teléfono fijo </b>
                            </Form.Label>
                            <Form.Control
                                className={styles.app_inputs_detail_patient}
                                type='text'
                                value={newPatient.homephone}
                                placeholder='Escribe...'
                                onChange={e => {
                                    setNewPatient({
                                        ...newPatient,
                                        homephone: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </div>
                </div>
            </div>
        );
    };

    const renderFormInformation = () => {
        return (
            <div className={styles.app_container_form_register}>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='docType'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Tipo de documento <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Select
                            noOptionsMessage={() => 'No hay datos'}
                            options={formatTypeDocument}
                            className='text-secondary'
                            placeholder={'Seleccionar...'}
                            value={formatTypeDocument.find(e => e.value === newPatient.docTypeId)}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    docTypeId: e.value,
                                    contracts: [],
                                });
                            }}
                            styles={appCustomSelectNew}
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='fN'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Número de documento <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            value={formatteDocument(newPatient.document).format}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    document: formatteDocument(e.target.value).value,
                                });
                            }}
                            onBlur={() => {
                                getValidateExistence({
                                    url: '/medical/patient/validateExistence/',
                                    token: token,
                                    objFilters: {
                                        documentType: newPatient.docTypeId,
                                        documentNumber: newPatient.document,
                                    },
                                    doAfterSuccess: data => {
                                        if (data.rowTotal > 0) message('error', `${data.title}`, data.message);
                                    },
                                });
                            }}
                            placeholder='Escribe...'
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Primer Nombre <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            value={newPatient.firstName}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    firstName: e.target.value,
                                });
                            }}
                            placeholder='Escribe...'
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='sName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Segundo Nombre </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            value={newPatient.otherNames}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    otherNames: e.target.value,
                                });
                            }}
                            placeholder='Escribe...'
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='fLs'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Primer Apellido <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            value={newPatient.lastName}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    lastName: e.target.value,
                                });
                            }}
                            placeholder='Escribe...'
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='sfr'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Segundo Apellido </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            value={newPatient.secondSurname}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    secondSurname: e.target.value,
                                });
                            }}
                            placeholder='Escribe...'
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='bombo'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Fecha de nacimiento <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='date'
                            value={newPatient.birthDate}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    birthDate: e.target.value,
                                });
                            }}
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='age'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Edad <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            placeholder='Escribe...'
                            value={`${newPatient.birthDate === '' ? '0' : getAge(newPatient.birthDate)} años`}
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='titi'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                WhatsApp <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            placeholder='Escribe...'
                            value={newPatient.whatsapp}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    whatsapp: e.target.value,
                                });
                            }}
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='me'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Género <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Select
                            noOptionsMessage={() => 'No hay datos'}
                            options={genderList}
                            className='text-secondary'
                            placeholder={'Seleccionar...'}
                            styles={appCustomSelectNew}
                            value={genderList.find(x => x.value === newPatient.gender)}
                            onChange={e => {
                                setNewPatient({ ...newPatient, gender: e.value });
                            }}
                        />
                    </Form.Group>
                </div>

                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='me'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Tipo de afiliado <span style={{ color: 'red' }}>*</span>
                            </b>
                        </Form.Label>
                        <Select
                            noOptionsMessage={() => 'No hay datos'}
                            options={listAffiliationTypes?.results?.map(state => ({
                                label: state.affiliationTypeName,
                                value: state.affiliationTypeId,
                            }))}
                            className='text-secondary'
                            placeholder={'Seleccionar...'}
                            styles={appCustomSelectNew}
                            value={{
                                label: newPatient?.affiliationTypeName,
                                value: newPatient
                                    ?.affiliationTypeId,
                            }}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    affiliationTypeName: e.label,
                                    affiliationTypeId: e.value,
                                    affiliationType: e.value,
                                });
                            }}
                        />
                    </Form.Group>
                </div>

                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='ask'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>
                                Correo electrónico
                            </b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='email'
                            value={newPatient.email}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    email: e.target.value,
                                });
                            }}
                            placeholder='Escribe...'
                        />
                    </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='otra'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Estado civil </b>
                        </Form.Label>
                        <Select
                            noOptionsMessage={() => 'No hay datos'}
                            options={maritalStatusList}
                            className='text-secondary'
                            placeholder={'Seleccionar...'}
                            styles={appCustomSelectNew}
                            value={maritalStatusList.find(x => x.value === newPatient.maritalStatus)}
                            onChange={e => {
                                setNewPatient({
                                    ...newPatient,
                                    maritalStatus: e.value,
                                });
                            }}
                        />
                    </Form.Group>
                </div>
            </div>
        );
    };

    return (
        <>
            {(loadCreatePatient || loading || loaderListAffiliationTypes || companyTypesLoader) && loader}
            <div className={styles.app_container_register_app}>
                <div
                    className={styles.app_container_close_app}
                    onClick={() => {
                        if (options.isEditingPatient) {
                            setOptions({
                                ...options,
                                showHandlePatient: false,
                                showDetailPatient: true,
                                showInfoPatient: false,
                                isEditingPatient: false,
                                showSchedule: false,
                            });
                        } else {
                            setOptions({
                                ...options,
                                showSearchPatient: true,
                                showHandlePatient: false,
                            });
                        }
                        setTrigger(trigger + 1);
                    }}
                >
                    <div className={styles.app_close} />
                </div>
                <div className={styles.app_container_register_patient}>
                    <div style={{ marginBottom: '10px' }}>
                        <div style={{ marginBottom: '10px' }}>
                            <span className={styles.app_title_register_app} style={{ fontSize: '40px' }}>
                                {options?.isEditingPatient ? 'Editar paciente' : 'Registrar paciente'}
                            </span>
                        </div>
                        {renderFormInformation()}
                    </div>
                    {renderAditionalInformation()}

                    {contracts.length > 0 &&
                        contracts.map(contract => {
                            return (
                                <RenderInsurance
                                    key={contract.index ?? contract.contractId}
                                    contract={contract}
                                    optionsEntityObligatory={optionsEntityObligatory}
                                    companyTypeOptions={companyTypeOptions}
                                    contractList={contracts}
                                    setContracts={setContracts}
                                />
                            );
                        })}

                    <div className={styles.app_container_icon_voluntary}>
                        <div
                            style={{
                                gap: '0.6rem',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                addInsurer();
                            }}
                        >
                            <div className={styles.btn_add_insurer} />
                            <span className={styles.app_title_insurer_voluntary}>
                                Agregar aseguradora voluntaria
                            </span>
                        </div>
                        <button
                            className={styles.btn_save_patient}
                            onClick={e => {
                                onSubmitPatient(e);
                            }}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', backgroundColor: '#F6FEFF' }}></div>
        </>
    );
};

const RenderInsurance = ({
    contract,
    optionsEntityObligatory,
    companyTypeOptions,
    contractList,
    setContracts,
}) => {
    const store = useSelector(state => state);
    const token = store.loginReducer.Authorization;
    const eaccount = store.loginReducer.currentAccount.id;
    const { results: contractsResults, trigger: getContracts } = useGetMethod();
    const { results: insurerVoluntaryResults, trigger: getInsurerVoluntary } = useGetMethod();
    const { results: poblationsResults, trigger: getPoblations } = useGetMethod();
    const { results: incomeGroupResults, trigger: getIncomeGroup } = useGetMethod();

    const contractsOptions = formatToRcSelect(contractsResults.results, 'id', 'name');
    const insuranceVoluntaryOptions = formatToRcSelect(insurerVoluntaryResults.results, 'id', 'name');
    const poblationOptions = formatToRcSelect(poblationsResults.results, 'idPopulation', 'name');
    const incomeGroupOptions = formatToRcSelect(
        incomeGroupResults.results,
        'crtId',
        'copaymentRateName',
    );

    let populationValue = ''

    let incomeGroupValue = ''

    if (contract?.contractId) {
        populationValue = contract?.populationId
            ? poblationOptions.find(
                x => x.value === contract.populationId
            )
            : poblationOptions?.[1];
    }

    if (contract?.contractId || contract?.companyTypeTag) {
        incomeGroupValue = contract?.crtId
            ? incomeGroupOptions.find(
                x => x.value === contract.crtId
            )
            : incomeGroupOptions?.[1];
    }

    useEffect(() => {
        if (!contract?.contractId) return
        const populationValue = contract?.populationId
            ? poblationOptions.find(x => x.value === contract.populationId)
            : poblationOptions?.[1];

        if (populationValue?.value && (contract.index || contract.relId)) {
            const updatedContracts = contractList.map(item =>
                (item?.index ?? item?.relId) === (contract.index ?? contract.relId)
                    ? {
                        ...item,
                        populationId: populationValue?.value,
                    }
                    : item
            );

            if (JSON.stringify(updatedContracts) !== JSON.stringify(contractList)) {
                setContracts(updatedContracts);
            }
        }
    }, [
        contract?.contractId,
        contract?.populationId,
        poblationOptions,
        contract.index,
        contract.relId,
        contractList,
        setContracts
    ]);

    const [contractFields, setContractFields] = useState({
        showPopulation: false,
        showIncomeGroup: false,
        showPolicy: true
    })

    useEffect(() => {
        if (!contract.companyTypeTag) {
            setContractFields({
                showPopulation: false,
                showIncomeGroup: false,
                showPolicy: true
            })
            return
        }

        const { companyTypeTag: companyType } = contract

        const {
            EPS,
            PREPAID,
            SPECIAL_AGREEMENT,
            OTHER
        } = companyTypeTags

        const shouldRenderPopulation = ![EPS, OTHER, PREPAID].includes(companyType)
        const shouldRenderIncomeGroup = ![EPS, OTHER].includes(companyType)
        const shouldRenderPolicy = companyType !== SPECIAL_AGREEMENT

        setContractFields({
            showPopulation: shouldRenderPopulation,
            showIncomeGroup: shouldRenderIncomeGroup,
            showPolicy: shouldRenderPolicy
        })
    }, [contract])

    useEffect(() => {
        if (!contract?.contractId) return
        const incomeGroupValue = contract?.crtId
            ? incomeGroupOptions.find(
                x => x.value === contract.crtId
            )
            : incomeGroupOptions?.[1];

        if (incomeGroupValue?.value && (contract.index || contract.relId) && (contract.isMain || contractFields.showIncomeGroup)) {
            const updatedContracts = contractList.map(item =>
                (item?.index ?? item?.relId) === (contract.index ?? contract.relId)
                    ? {
                        ...item,
                        crtId: incomeGroupValue?.value,
                    }
                    : item
            );

            if (JSON.stringify(updatedContracts) !== JSON.stringify(contractList)) {
                setContracts(updatedContracts);
            }
        }
    }, [
        contract?.contractId,
        contract?.crtId,
        incomeGroupOptions,
        contract.index,
        contract.relId,
        contractList,
        setContracts,
        contractFields.showIncomeGroup,
        contract.isMain
    ]);

    const deleteInsurer = id => {
        setContracts(contractList.filter(item => (item.index ?? item.relId) !== id));
    };

    const getContractsFunction = (id, companyType) => {
        getContracts({
            url: '/medical/client/contract/',
            token: token,
            objFilters: {
                client: id,
                cType: companyType,
                status: 'enabled',
                eaccount,
            },
        });
    };

    const getInsurersVoluntaryFunction = id => {
        getInsurerVoluntary({
            url: '/medical/client/',
            token: token,
            objFilters: { contractCompanyType: id, status: 'enabled', eaccount },
        });
    };

    const getPoblationsFunction = id => {
        getPoblations({
            url: '/medical/client/contractpopulation/',
            token: token,
            objFilters: {
                contract: id,
                status: 'enabled',
                eaccount,
            },
        });
    };

    const getIncomeGroupFunction = id => {
        getIncomeGroup({
            url: '/medical/client/copaymentRate/',
            token: token,
            objFilters: { eaccount, corpclientId: id },
        });
    };

    const onChangeInsureObligatory = (value, id) => {
        getContractsFunction(value, 'eps');
        getIncomeGroupFunction(value);

        const tempArray = contractList.map(item =>
            (item?.index ?? item?.relId) === id
                ? {
                    ...item,
                    corporateClientId: value,
                    contractId: '',
                    populationId: '',
                    crtId: '',
                    policy: '',
                }
                : item,
        );
        setContracts(tempArray);
    };

    const onChangeInsureVoluntary = (value, id) => {
        getContractsFunction(value, contract.companyTypeTag);
        getIncomeGroupFunction(value)

        const tempArray = contractList.map(item =>
            (item?.index ?? item?.relId) === id
                ? {
                    ...item,
                    corporateClientId: value,
                    contractId: '',
                    populationId: '',
                    crtId: '',
                }
                : item,
        );
        setContracts(tempArray);
    };

    const onChangeCompanyTypeVoluntary = (value, id) => {
        getInsurersVoluntaryFunction(value);
        const tempArray = contractList.map(item =>
            (item.index ?? item.relId) === id
                ? {
                    ...item,
                    companyTypeTag: value,
                    corporateClientId: '',
                    contractId: '',
                    policy: '',
                }
                : item,
        );
        setContracts(tempArray);
    };

    const onChangeContract = (value, id) => {
        getPoblationsFunction(value);
        const tempArray = contractList.map(item =>
            (item?.index ?? item?.relId) === id
                ? {
                    ...item,
                    contractId: value,
                    populationId: '',
                }
                : item
        );
        setContracts(tempArray);
    };

    const onChangePoblation = (value, id) => {
        const tempArray = contractList.map(item =>
            (item?.index ?? item?.relId) === id
                ? {
                    ...item,
                    populationId: value,
                }
                : item,
        );
        setContracts(tempArray);
    };

    const onChangeIncomeGroupObligatory = (value, id) => {
        const tempArray = contractList.map(item =>
            (item?.index ?? item?.relId) === id
                ? {
                    ...item,
                    crtId: value,
                }
                : item,
        );
        setContracts(tempArray);
    };

    const onChangePolicy = (value, id) => {
        const tempArray = contractList.map(item =>
            (item?.index ?? item?.relId) === id
                ? {
                    ...item,
                    policy: value,
                }
                : item,
        );
        setContracts(tempArray);
    };

    
    const renderObligatoryInsurance = () => {
        const existParticular = contractList.some(item => item.companyTypeTag === 'particular');

        return (
            <div className={styles.app_container_register_insurance}>
                <div>
                    <span className={styles.app_subtitle_register_app}>Aseguradora obligatoria</span>
                </div>
                <div className={styles.app_container_form_register}>
                    <div className={styles.app_container_content_form}>
                        <Form.Group>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Seguro</b>
                                {!existParticular && (
                                    <span style={{ color: 'red' }}>*</span>
                                )}
                            </Form.Label>

                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={optionsEntityObligatory}
                                styles={appCustomSelectNew}
                                placeholder={'Seleccionar...'}
                                value={optionsEntityObligatory.find(x => x.value === contract.corporateClientId)}
                                onChange={e => {
                                    onChangeInsureObligatory(e.value, contract?.index ?? contract?.relId);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='pero'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Plan</b>
                                {!existParticular && (
                                    <span style={{ color: 'red' }}>*</span>
                                )}
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={contractsOptions}
                                styles={appCustomSelectNew}
                                placeholder={'Seleccionar...'}
                                value={contractsOptions.find(x => x.value === contract.contractId)}
                                onChange={e => {
                                    onChangeContract(e.value, contract?.index ?? contract.relId);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Población</b>
                                {!existParticular && (
                                    <span style={{ color: 'red' }}>*</span>
                                )}
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={poblationOptions}
                                styles={appCustomSelectNew}
                                placeholder={'Seleccionar...'}
                                value={populationValue}
                                onChange={e => {
                                    onChangePoblation(e.value, contract?.index ?? contract.relId);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='trust'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Grupo de ingreso</b>
                                {!existParticular && (
                                    <span style={{ color: 'red' }}>*</span>
                                )}
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={incomeGroupOptions}
                                styles={appCustomSelectNew}
                                placeholder={'Seleccionar...'}
                                value={incomeGroupValue}
                                onChange={e => {
                                    onChangeIncomeGroupObligatory(e.value, contract?.index ?? contract?.relId);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                            <Form.Group controlId='knj2'>
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className='px-1'>No. de Póliza</b>
                                    {(contract.companyTypeTag === companyTypeTags.PREPAID && !existParticular) && (
                                        <span style={{ color: '#F3E882' }}>*</span>
                                    )}
                                </Form.Label>
                                <Form.Control
                                    className={styles.app_inputs_detail_patient}
                                    type='text'
                                    value={contract.policy}
                                    placeholder='Escribe...'
                                    onChange={e => {
                                        onChangePolicy(e.target.value, contract?.index ?? contract?.relId);
                                    }}
                                />
                            </Form.Group>
                        </div>
                </div>
            </div>
        );
    };

    const renderVoluntaryInsurance = () => {
        return (
            <div className={styles.app_container_insurance_voluntary}>
                <div className={styles.container_title_insurer_voluntary}>
                    <span className={styles.app_insurer_voluntary}>Aseguradora voluntaria</span>
                    <div>
                        <img
                            src={closeInsurer}
                            alt='close'
                            className='cursorPointer'
                            width={13}
                            style={{ color: '#A3BAD1' }}
                            onClick={() => {
                                deleteInsurer(contract?.index ?? contract.relId);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.app_container_form_register}>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='Ftype'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Tipo de empresa</b>
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={companyTypeOptions?.filter(type => type.extra !== 'eps')}
                                styles={appCustomSelectNew}
                                value={companyTypeOptions.find(x => x.extra === contract.companyTypeTag)}
                                placeholder={'Seleccionar...'}
                                onChange={e => {
                                    onChangeCompanyTypeVoluntary(e.extra, contract.index ?? contract.relId);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='Company'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Seguro</b>
                                <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={insuranceVoluntaryOptions}
                                styles={appCustomSelectNew}
                                placeholder={'Seleccionar...'}
                                value={insuranceVoluntaryOptions.find(x => x.value === contract.corporateClientId)}
                                onChange={e => {
                                    onChangeInsureVoluntary(e.value, contract?.index ?? contract?.relId);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='fcontr'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Plan </b>
                                <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Select
                                noOptionsMessage={() => 'No hay datos'}
                                options={contractsOptions}
                                styles={appCustomSelectNew}
                                placeholder={'Seleccionar...'}
                                value={contractsOptions.find(x => x.value === contract.contractId)}
                                onChange={e => {
                                    onChangeContract(e.value, contract?.index ?? contract.relId);
                                }}
                            />
                        </Form.Group>
                    </div>

                    {contractFields.showPopulation && (
                        <div className={styles.app_container_content_form}>
                            <Form.Group controlId='frm2'>
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className='px-1'>Población</b>
                                    <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    options={poblationOptions}
                                    styles={appCustomSelectNew}
                                    placeholder={'Seleccionar...'}
                                    value={populationValue}
                                    onChange={e => {
                                        onChangePoblation(e.value, contract?.index ?? contract?.relId);
                                    }}
                                />
                            </Form.Group>
                        </div>
                    )}

                    {contractFields.showIncomeGroup && (
                        <div className={styles.app_container_content_form}>
                            <Form.Group controlId='cnj2'>
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className='px-1'>Grupo de ingreso</b>
                                    <span style={{ color: 'red' }}>*</span>
                                </Form.Label>
                                <Select
                                    noOptionsMessage={() => 'No hay datos'}
                                    options={incomeGroupOptions}
                                    styles={appCustomSelectNew}
                                    placeholder={'Seleccionar...'}
                                    value={incomeGroupValue}
                                    onChange={e => {
                                        onChangeIncomeGroupObligatory(e.value, contract?.index ?? contract.relId);
                                    }}
                                />
                            </Form.Group>
                        </div>
                    )}

                    {contractFields.showPolicy && (
                        <div className={styles.app_container_content_form}>
                            <Form.Group controlId='knj2'>
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className='px-1'>No. de Póliza</b>
                                    {contract.companyTypeTag === companyTypeTags.PREPAID && (
                                        <span style={{ color: 'red' }}>*</span>
                                    )}
                                </Form.Label>
                                <Form.Control
                                    className={styles.app_inputs_detail_patient}
                                    type='text'
                                    value={contract.policy}
                                    placeholder='Escribe...'
                                    onChange={e => {
                                        onChangePolicy(e.target.value, contract?.index ?? contract?.relId);
                                    }}
                                />
                            </Form.Group>
                        </div>
                    )}
                </div>
            </div >
        );
    };

    useEffect(() => {
        if (contract.corporateClientId) {
            if (contract.isMain === 1) {
                onChangeInsureObligatory(contract.corporateClientId, contract?.relId);
                onChangeIncomeGroupObligatory(contract.crtId, contract?.relId);
            } else {
                onChangeCompanyTypeVoluntary(contract.companyTypeTag, contract?.relId);
                onChangeInsureVoluntary(contract.corporateClientId, contract?.relId);
            }

            if (contract.contractId) {
                onChangeContract(contract.contractId, contract.relId);
            }

            if (contract.populationId) {
                onChangePoblation(contract.populationId, contract.relId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return contract.isMain ? renderObligatoryInsurance() : renderVoluntaryInsurance();
};

export default HandlePatient;
